// src/contexts/ThemeContext.jsx
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Sun, Moon, Cloud, Waves, Trees } from 'lucide-react';

// Theme definitions with proper type structure
export const Themes = {
  clouds: {
    id: 'clouds',
    name: 'Clouds',
    icon: Cloud,
    background: {
      light: '/backgrounds/pink-clouds.jpg',
      dark: '/backgrounds/stars.jpg'
    },
    colors: {
      primary: {
        dark: '#9B4F96',
        main: '#D67BFF',
        light: '#FFB3FF',
      },
      accent: {
        main: '#F6E6FF',
        light: '#FFE5F1',
      },
      text: {
        light: {
          primary: 'rgba(45, 55, 72, 1)',
          secondary: 'rgba(45, 55, 72, 0.8)'
        },
        dark: {
          primary: 'rgba(255, 255, 255, 1)',
          secondary: 'rgba(255, 255, 255, 0.8)'
        }
      }
    }
  },
  ocean: {
    id: 'ocean',
    name: 'Ocean',
    icon: Waves,
    background: {
      light: '/backgrounds/underwater.jpg',
      dark: '/backgrounds/night-ocean.jpg'
    },
    colors: {
      primary: {
        dark: '#0C356A',
        main: '#0174BE',
        light: '#36ADFF',
      },
      accent: {
        main: '#E4F1FF',
        light: '#F0F9FF',
      },
      text: {
        light: {
          primary: 'rgba(10, 40, 80, 1)',
          secondary: 'rgba(10, 40, 80, 0.8)'
        },
        dark: {
          primary: 'rgba(235, 248, 255, 1)',
          secondary: 'rgba(235, 248, 255, 0.8)'
        }
      }
    }
  },
  forest: {
    id: 'forest',
    name: 'Forest',
    icon: Trees,
    background: {
      light: '/backgrounds/forest.jpg',
      dark: '/backgrounds/night-forest.jpg'
    },
    colors: {
      primary: {
        dark: '#1B4332',
        main: '#2D6A4F',
        light: '#95D5B2',
      },
      accent: {
        main: '#D8F3DC',
        light: '#F0FFF4',
      },
      text: {
        light: {
          primary: 'rgba(27, 67, 50, 1)',
          secondary: 'rgba(27, 67, 50, 0.8)'
        },
        dark: {
          primary: 'rgba(240, 255, 244, 1)',
          secondary: 'rgba(240, 255, 244, 0.8)'
        }
      }
    }
  }
};

const ThemeContext = createContext(undefined);

// Theme switcher component with improved mobile handling
const ThemeSwitcher = ({ currentTheme, onThemeChange, isDarkMode, onDarkModeChange }) => {
  const [isOpen, setIsOpen] = useState(false);

  // Close menu when clicking outside
  useEffect(() => {
    if (!isOpen) return;

    const handleClickOutside = (event) => {
      if (!event.target.closest('.theme-switcher')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  // Close menu on scroll
  useEffect(() => {
    if (!isOpen) return;

    const handleScroll = () => {
      setIsOpen(false);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isOpen]);

  return (
    <div className="theme-switcher relative">
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={() => setIsOpen(!isOpen)}
        className="p-3 rounded-xl backdrop-blur-md shadow-lg"
        style={{
          backgroundColor: isDarkMode 
            ? 'rgba(255, 255, 255, 0.1)' 
            : 'rgba(0, 0, 0, 0.05)'
        }}
      >
        <currentTheme.icon className="w-5 h-5" />
      </motion.button>

      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, y: -10, scale: 1.95 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, y: -10, scale: 0.95 }}
            transition={{ duration: 0.2 }}
            className="absolute right-0 mt-2 p-2 rounded-xl shadow-lg backdrop-blur-md z-50 overflow-hidden"
            style={{
              backgroundColor: isDarkMode 
                ? 'rgba(0, 0, 0, 0.8)' 
                : 'rgba(255, 255, 255, 0.8)',
              width: '200px'
            }}
          >
            <motion.button
              onClick={() => onDarkModeChange(!isDarkMode)}
              className="w-full p-2 rounded-lg flex items-center gap-3"
              whileHover={{ backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)' }}
            >
              {isDarkMode ? <Sun className="w-4 h-4" /> : <Moon className="w-4 h-4" />}
              <span>{isDarkMode ? 'Light Mode' : 'Dark Mode'}</span>
            </motion.button>

            <div className="h-px bg-current opacity-100 my-2" />

            {Object.values(Themes).map((theme) => (
              <motion.button
                key={theme.id}
                onClick={() => {
                  onThemeChange(theme);
                  setIsOpen(false);
                }}
                className="w-full p-2 rounded-lg flex items-center gap-3"
                whileHover={{ backgroundColor: isDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.05)' }}
              >
                <theme.icon className="w-4 h-4" />
                <span>{theme.name}</span>
                {currentTheme.id === theme.id && (
                  <motion.div
                    layoutId="activeTheme"
                    className="ml-auto w-2 h-2 rounded-full"
                    style={{ backgroundColor: theme.colors.primary.main }}
                  />
                )}
              </motion.button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export const ThemeProvider = ({ children }) => {
  const [currentTheme, setCurrentTheme] = useState(Themes.clouds);
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Load saved preferences with preload
  useEffect(() => {
    // Preload background images
    const preloadImages = () => {
      Object.values(Themes).forEach(theme => {
        const lightImg = new Image();
        const darkImg = new Image();
        lightImg.src = theme.background.light;
        darkImg.src = theme.background.dark;
      });
    };

    const loadPreferences = () => {
      const savedTheme = localStorage.getItem('theme');
      const savedDarkMode = localStorage.getItem('darkMode');
      
      if (savedTheme && Themes[savedTheme]) {
        setCurrentTheme(Themes[savedTheme]);
      }
      if (savedDarkMode !== null) {
        setIsDarkMode(savedDarkMode === 'true');
      }
      setIsLoading(false);
    };

    // Start preloading immediately
    preloadImages();
    
    // Load preferences after a short delay to allow for smooth mount
    requestAnimationFrame(loadPreferences);
  }, []);

  // Save preferences with debouncing
  const savePreferences = useCallback(() => {
    if (!isLoading) {
      localStorage.setItem('theme', currentTheme.id);
      localStorage.setItem('darkMode', isDarkMode.toString());
    }
  }, [currentTheme.id, isDarkMode, isLoading]);

  useEffect(() => {
    const timeoutId = setTimeout(savePreferences, 500);
    return () => clearTimeout(timeoutId);
  }, [savePreferences]);

  const handleThemeChange = useCallback((newTheme) => {
    setCurrentTheme(newTheme);
  }, []);

  const handleDarkModeChange = useCallback((newMode) => {
    setIsDarkMode(newMode);
  }, []);

  const contextValue = {
    theme: currentTheme,
    setTheme: handleThemeChange,
    isDarkMode,
    setIsDarkMode: handleDarkModeChange,
    isLoading
  };

  if (isLoading) {
    return null; // or a loading spinner
  }

  return (
    <ThemeContext.Provider value={contextValue}>
      <div 
        className={`min-h-screen transition-colors duration-300 ${
          isDarkMode ? 'dark text-white' : 'text-gray-900'
        }`}
      >
        {/* Theme Switcher */}
        <div className="fixed top-4 right-4 z-50">
          <ThemeSwitcher
            currentTheme={currentTheme}
            onThemeChange={handleThemeChange}
            isDarkMode={isDarkMode}
            onDarkModeChange={handleDarkModeChange}
          />
        </div>

        {/* Content */}
        <div className="relative min-h-screen">
          {children}
        </div>
      </div>
    </ThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};