// contexts/AuthContext.jsx
import React, { createContext, useContext, useState, useEffect } from 'react';
import { firebaseService } from '../services/data/firebaseService';

const AuthContext = createContext();

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = firebaseService.auth.onAuthStateChanged(user => {
      if (user) {
        // Format user data with essential info
        setUser({
          uid: user.uid,
          email: user.email,
          firstName: user.email.split('@')[0], // Extract name from email
        });
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const login = async (email, password) => {
    return firebaseService.login(email, password);
  };

  const logout = async () => {
    return firebaseService.logout();
  };

  const value = {
    user,
    loading,
    login,
    logout
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;