// index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider } from './components/ui/ThemeProvider';
import './index.css';
import { unifiedKnowledgeService } from './services/knowledge/UnifiedKnowledgeService';



// Initialize app
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ThemeProvider>
  </React.StrictMode>
);
// Initialize knowledge service
unifiedKnowledgeService.initialize();