// services/knowledge/unifiedKnowledgeService.js
import { ref, onValue, set, get } from 'firebase/database';
import { database } from '../../firebase';
import { calendarService } from './calendarService';

const KNOWLEDGE_CONFIG = {
  updateIntervals: {
    jewish: 1000 * 60 * 60,      // 1 hour
    sports: 1000 * 60 * 15       // 15 minutes
  },
  sports: {
    rssSources: {
      football: 'https://www.eyefootball.com/football_news.xml'
    }
  }
};

class UnifiedKnowledgeService {
  constructor() {
    this.knowledgeRef = ref(database, 'publicKnowledge');
    this.cache = new Map();
    this.initialized = false;
  }

  async initialize() {
    if (this.initialized) return;
    
    try {
      await this.updateAllKnowledge();
      this.setupKnowledgeListeners();
      this.initialized = true;
    } catch (error) {
      console.error('Error initializing knowledge service:', error);
      throw error;
    }
  }

  async fetchRSSFeed(url) {
    try {
      const response = await fetch(url);
      const text = await response.text();
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(text, 'text/xml');
      
      const items = xmlDoc.querySelectorAll('item');
      return Array.from(items).map(item => ({
        title: item.querySelector('title')?.textContent || '',
        pubDate: item.querySelector('pubDate')?.textContent || '',
        link: item.querySelector('link')?.textContent || '',
        description: item.querySelector('description')?.textContent
          .replace(/<[^>]*>/g, '') // Remove HTML tags
          .replace(/\[\]|\[CDATA\]/g, '') // Remove CDATA
          .trim(),
        guid: item.querySelector('guid')?.textContent || ''
      }));
    } catch (error) {
      console.error('Error fetching RSS feed:', error);
      return [];
    }
  }

  async updateAllKnowledge() {
    const updates = {};

    try {
      // Update Jewish knowledge
      updates['jewish'] = {
        parsha: await calendarService.getParsha(),
        zmanim: await calendarService.getZmanim(),
        events: await calendarService.getJewishCalendarInfo(),
        lastUpdated: new Date().toISOString()
      };

      // Update Sports knowledge from RSS
      const footballNews = await this.fetchRSSFeed(KNOWLEDGE_CONFIG.sports.rssSources.football);
      updates['sports'] = {
        football: {
          news: footballNews,
          lastUpdated: new Date().toISOString()
        }
      };

      await set(this.knowledgeRef, updates);
      return updates;
    } catch (error) {
      console.error('Error updating knowledge:', error);
      throw error;
    }
  }

  setupKnowledgeListeners() {
    onValue(this.knowledgeRef, async (snapshot) => {
      const data = snapshot.val();
      if (!data) return;

      Object.entries(data).forEach(async ([category, categoryData]) => {
        const updateInterval = KNOWLEDGE_CONFIG.updateIntervals[category];
        if (this.isDataStale(categoryData?.lastUpdated, updateInterval)) {
          await this.updateCategoryKnowledge(category);
        }
      });
    });
  }

  async updateCategoryKnowledge(category) {
    const categoryRef = ref(database, `publicKnowledge/${category}`);
    let newData = {};

    try {
      switch (category) {
        case 'jewish':
          newData = {
            parsha: await calendarService.getParsha(),
            zmanim: await calendarService.getZmanim(),
            events: await calendarService.getJewishCalendarInfo(),
            lastUpdated: new Date().toISOString()
          };
          break;

        case 'sports':
          const footballNews = await this.fetchRSSFeed(KNOWLEDGE_CONFIG.sports.rssSources.football);
          newData = {
            football: {
              news: footballNews,
              lastUpdated: new Date().toISOString()
            }
          };
          break;
      }

      await set(categoryRef, newData);
    } catch (error) {
      console.error(`Error updating ${category} knowledge:`, error);
    }
  }

  isDataStale(lastUpdated, interval = 3600000) {
    if (!lastUpdated) return true;
    const now = new Date();
    const lastUpdate = new Date(lastUpdated);
    return (now - lastUpdate) > interval;
  }

  async getContextForPrompt(message = '') {
    if (!message) return '';

    const keywords = message.toLowerCase().split(/\W+/);
    let context = '';

    try {
      const snapshot = await get(this.knowledgeRef);
      const data = snapshot.val();
      if (!data) return '';

      // Jewish context (unchanged)
      if (keywords.some(k => ['torah', 'parsha', 'jewish', 'shabbat', 'mincha', 'maariv'].includes(k))) {
        const jewishData = data.jewish;
        if (jewishData) {
          context += '\nJewish Context:\n';
          if (jewishData.parsha) {
            context += `Current Parsha: ${jewishData.parsha.name}\n`;
          }
          if (jewishData.zmanim) {
            context += calendarService.formatZmanimForPrompt(jewishData.zmanim) + '\n';
          }
          if (jewishData.events) {
            context += calendarService.formatEventsForPrompt(jewishData.events) + '\n';
          }
        }
      }

      // Football/Sports context
      if (keywords.some(k => ['soccer', 'football', 'transfer', 'sports', 'premier'].includes(k))) {
        const sportsData = data.sports?.football;
        if (sportsData?.news?.length) {
          context += '\nLatest Football News:\n';
          sportsData.news.slice(0, 5).forEach((item, index) => {
            context += `${index + 1}. ${item.title}\n   ${item.description}\n\n`;
          });
        }
      }

      return context.trim();
    } catch (error) {
      console.error('Error getting context:', error);
      return '';
    }
  }
}

export const unifiedKnowledgeService = new UnifiedKnowledgeService();