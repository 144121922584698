// src/views/FamilyView.jsx
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { motion, AnimatePresence } from 'framer-motion';
import { useTheme } from '../contexts/ThemeContext';
import TopNavigation from '../components/ui/TopNavigation';
import ChatBubble from '../components/chat/ChatBubble';
import InputArea from '../components/chat/InputArea';
import { firebaseService } from '../services/data/firebaseService';

const FamilyView = () => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSpeaking, setIsSpeaking] = useState(false);
  const { user } = useAuth();
  const { theme, isDarkMode, setIsDarkMode } = useTheme();
  const messagesEndRef = useRef(null);
  const containerRef = useRef(null);

  // Subscribe to family chat messages
  useEffect(() => {
    if (!user) return;

    const unsubscribe = firebaseService.subscribeFamilyChat((updatedMessages) => {
      const sortedMessages = updatedMessages.sort((a, b) => {
        const timestampA = a.timestamp ? new Date(a.timestamp).getTime() : 0;
        const timestampB = b.timestamp ? new Date(b.timestamp).getTime() : 0;
        return timestampA - timestampB;
      });
      setMessages(sortedMessages);
    });

    return () => unsubscribe && unsubscribe();
  }, [user]);

  // Group messages by user and time
  const groupedMessages = useMemo(() => {
    const groups = [];
    let currentGroup = [];
    
    messages.forEach((message, index) => {
      const prevMessage = messages[index - 1];
      const nextMessage = messages[index + 1];
      
      // Start new group if:
      // 1. First message
      // 2. Different user from previous message
      // 3. More than 2 minutes from previous message
      if (
        !prevMessage ||
        prevMessage.userId !== message.userId ||
        Math.abs(new Date(message.timestamp) - new Date(prevMessage.timestamp)) > 120000
      ) {
        if (currentGroup.length > 0) {
          groups.push(currentGroup);
        }
        currentGroup = [message];
      } else {
        currentGroup.push(message);
      }
      
      // Push last group
      if (index === messages.length - 1) {
        groups.push(currentGroup);
      }
    });
    
    return groups;
  }, [messages]);

  // Improved scroll handling
  useEffect(() => {
    if (!messagesEndRef.current || !containerRef.current) return;

    const shouldAutoScroll = 
      containerRef.current.scrollTop + containerRef.current.clientHeight >=
      containerRef.current.scrollHeight - 100;

    if (shouldAutoScroll) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const handleSendMessage = async (content) => {
    if (!content.trim() || !user || isLoading) return;

    setIsLoading(true);
    try {
      await firebaseService.sendFamilyMessage(user.uid, content);
      // Always scroll to bottom on send
      messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    } catch (error) {
      console.error('Error sending message:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    try {
      await firebaseService.deleteMessage('family', messageId);
    } catch (error) {
      console.error('Error deleting message:', error);
    }
  };

  const handleTextToSpeech = (content) => {
    if (isSpeaking) {
      window.speechSynthesis.cancel();
      setIsSpeaking(false);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(content);
    utterance.onend = () => setIsSpeaking(false);
    utterance.onstart = () => setIsSpeaking(true);
    window.speechSynthesis.speak(utterance);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <TopNavigation
        theme={theme}
        isDarkMode={isDarkMode}
        setIsDarkMode={setIsDarkMode}
      />

      <div 
        className="flex-1 overflow-hidden relative"
        style={{
          backgroundImage: `url(${isDarkMode ? theme.background.dark : theme.background.light})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundAttachment: 'fixed'
        }}
      >
        {/* Messages Container */}
        <div 
          ref={containerRef}
          className="h-full overflow-y-auto px-4 pb-20 scroll-smooth"
        >
          <div className="max-w-3xl mx-auto space-y-2 pt-4">
            <AnimatePresence initial={false}>
              {groupedMessages.map((group, groupIndex) => (
                <motion.div key={group[0].id} className="space-y-1">
                  {group.map((message, messageIndex) => (
                    <ChatBubble
                      key={message.id}
                      message={message}
                      isCurrentUser={message.userId === user.uid}
                      isFirstInGroup={messageIndex === 0}
                      isLastInGroup={messageIndex === group.length - 1}
                      theme={theme}
                      isDarkMode={isDarkMode}
                      onDoubleTap={handleTextToSpeech}
                      onDelete={
                        message.userId === user.uid
                          ? () => handleDeleteMessage(message.id)
                          : undefined
                      }
                    />
                  ))}
                </motion.div>
              ))}
            </AnimatePresence>
            
            {isLoading && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                className="flex justify-center"
              >
                <div className="loading-indicator" />
              </motion.div>
            )}
            <div ref={messagesEndRef} className="h-px" />
          </div>
        </div>

        {/* Input Area */}
        <InputArea
          onSendMessage={handleSendMessage}
          isLoading={isLoading}
          theme={theme}
          isDarkMode={isDarkMode}
        />
      </div>
    </div>
  );
};

export default FamilyView;