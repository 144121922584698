// services/knowledge/calendarService.js

class CalendarService {
  constructor() {
    this.baseUrl = 'https://www.hebcal.com/hebcal';
    this.geoUrl = 'https://www.hebcal.com/zmanim';
    this.cache = new Map();
    this.cacheExpiry = 1000 * 60 * 60; // 1 hour

    // Johannesburg coordinates and timezone
    this.defaultLocation = {
      latitude: -26.2041,
      longitude: 28.0473,
      timezone: 'Africa/Johannesburg',
      locationName: 'Johannesburg'
    };
  }

  // Helper to format time in 12-hour format
  formatTime(date) {
    return date.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
      timeZone: this.defaultLocation.timezone
    });
  }

  async getJewishCalendarInfo(options = {}) {
    try {
      const date = new Date();
      const cacheKey = `calendar-${date.toDateString()}`;
      
      // Check cache first
      if (this.cache.has(cacheKey)) {
        const cached = this.cache.get(cacheKey);
        if (Date.now() - cached.timestamp < this.cacheExpiry) {
          console.log('Using cached calendar data');
          return cached.data;
        }
        this.cache.delete(cacheKey);
      }

      console.log('Fetching fresh calendar data');
      // Build query parameters
      const params = new URLSearchParams({
        cfg: 'json',
        maj: 'on',  // Major holidays
        min: 'on',  // Minor holidays
        mod: 'on',  // Modern holidays
        nx: 'on',   // Rosh Chodesh
        year: 'now',
        month: 'now',
        // Location parameters
        latitude: this.defaultLocation.latitude,
        longitude: this.defaultLocation.longitude,
        tzid: this.defaultLocation.timezone,
        ...options
      });

      const response = await fetch(`${this.baseUrl}?${params}`);
      if (!response.ok) throw new Error('Failed to fetch calendar data');

      const data = await response.json();
      
      // Process and structure the data
      const events = data.items.map(item => ({
        title: item.title,
        hebrew: item.hebrew,
        date: new Date(item.date),
        category: item.category,
        subcat: item.subcat,
        link: item.link,
        memo: item.memo,
        formattedDate: new Date(item.date).toLocaleDateString('en-US', {
          weekday: 'long',
          month: 'long',
          day: 'numeric'
        })
      }));

      // Cache the results
      this.cache.set(cacheKey, {
        timestamp: Date.now(),
        data: events
      });

      return events;
    } catch (error) {
      console.error('Error fetching Jewish calendar:', error);
      throw error;
    }
  }

  async getZmanim(latitude = this.defaultLocation.latitude, 
                 longitude = this.defaultLocation.longitude, 
                 options = {}) {
    try {
      const date = new Date();
      const cacheKey = `zmanim-${date.toDateString()}-${latitude}-${longitude}`;
      
      if (this.cache.has(cacheKey)) {
        const cached = this.cache.get(cacheKey);
        if (Date.now() - cached.timestamp < this.cacheExpiry) {
          console.log('Using cached zmanim data');
          return cached.data;
        }
        this.cache.delete(cacheKey);
      }

      console.log('Fetching fresh zmanim data');
      const params = new URLSearchParams({
        cfg: 'json',
        latitude,
        longitude,
        tzid: this.defaultLocation.timezone,
        date: date.toISOString().split('T')[0],
        ...options
      });

      const response = await fetch(`${this.geoUrl}?${params}`);
      if (!response.ok) throw new Error('Failed to fetch zmanim');

      const data = await response.json();
      
      // Process zmanim data with formatted times
      const zmanim = {
        date: new Date(data.date),
        location: {
          latitude: data.latitude,
          longitude: data.longitude,
          timezone: data.timezone,
          name: this.defaultLocation.locationName
        },
        times: {
          sunrise: new Date(data.times.sunrise),
          sunset: new Date(data.times.sunset),
          chatzot: new Date(data.times.chatzot),
          minchaGedola: new Date(data.times.minchaGedola),
          minchaKetana: new Date(data.times.minchaKetana),
          plag: new Date(data.times.plag),
          tzeit: new Date(data.times.tzeit),
          alot: new Date(data.times.alot),
          shema: new Date(data.times.shema)
        },
        formatted: {
          sunrise: this.formatTime(new Date(data.times.sunrise)),
          sunset: this.formatTime(new Date(data.times.sunset)),
          chatzot: this.formatTime(new Date(data.times.chatzot)),
          minchaGedola: this.formatTime(new Date(data.times.minchaGedola)),
          minchaKetana: this.formatTime(new Date(data.times.minchaKetana)),
          plag: this.formatTime(new Date(data.times.plag)),
          tzeit: this.formatTime(new Date(data.times.tzeit)),
          alot: this.formatTime(new Date(data.times.alot)),
          shema: this.formatTime(new Date(data.times.shema))
        }
      };

      this.cache.set(cacheKey, {
        timestamp: Date.now(),
        data: zmanim
      });

      return zmanim;
    } catch (error) {
      console.error('Error fetching zmanim:', error);
      throw error;
    }
  }

  async getShabbatTimes(date = new Date()) {
    try {
      const params = new URLSearchParams({
        cfg: 'json',
        latitude: this.defaultLocation.latitude,
        longitude: this.defaultLocation.longitude,
        tzid: this.defaultLocation.timezone,
        date: date.toISOString().split('T')[0]
      });

      const response = await fetch(`${this.geoUrl}?${params}`);
      if (!response.ok) throw new Error('Failed to fetch Shabbat times');

      const data = await response.json();
      const parsha = await this.getParsha();

      return {
        location: this.defaultLocation.locationName,
        candles: this.formatTime(new Date(data.times.sunset)),
        havdalah: this.formatTime(new Date(data.times.tzeit)),
        parsha,
        formatted: `Shabbat Times for ${this.defaultLocation.locationName}:
Candle Lighting: ${this.formatTime(new Date(data.times.sunset))}
Havdalah: ${this.formatTime(new Date(data.times.tzeit))}
Parsha: ${parsha ? parsha.name : 'N/A'}`
      };
    } catch (error) {
      console.error('Error fetching Shabbat times:', error);
      throw error;
    }
  }

  async getParsha(options = {}) {
    try {
      const params = new URLSearchParams({
        cfg: 'json',
        maj: 'on',
        nx: 'on',
        year: 'now',
        month: 'now',
        latitude: this.defaultLocation.latitude,
        longitude: this.defaultLocation.longitude,
        tzid: this.defaultLocation.timezone,
        ...options
      });

      const response = await fetch(`${this.baseUrl}?${params}`);
      if (!response.ok) throw new Error('Failed to fetch parsha');

      const data = await response.json();
      
      // Find the next parsha
      const parsha = data.items.find(item => 
        item.category === 'parashat' && 
        new Date(item.date) >= new Date()
      );

      if (parsha) {
        return {
          name: parsha.title.replace('Parashat ', ''),
          hebrew: parsha.hebrew,
          date: new Date(parsha.date),
          link: parsha.link,
          formattedDate: new Date(parsha.date).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric'
          })
        };
      }

      return null;
    } catch (error) {
      console.error('Error fetching parsha:', error);
      throw error;
    }
  }

  formatEventsForPrompt(events) {
    if (!events?.length) return '';

    return events
      .map(event => {
        return `${event.title} (${event.hebrew}) - ${event.formattedDate}${
          event.memo ? `\n   ${event.memo}` : ''
        }`;
      })
      .join('\n');
  }

  formatZmanimForPrompt(zmanim) {
    if (!zmanim) return '';

    return `Zmanim for ${zmanim.location.name}:
Sunrise (Netz): ${zmanim.formatted.sunrise}
Shema Latest: ${zmanim.formatted.shema}
Chatzot: ${zmanim.formatted.chatzot}
Mincha Gedola: ${zmanim.formatted.minchaGedola}
Mincha Ketana: ${zmanim.formatted.minchaKetana}
Plag HaMincha: ${zmanim.formatted.plag}
Sunset (Shkia): ${zmanim.formatted.sunset}
Tzeit HaKochavim: ${zmanim.formatted.tzeit}`;
  }

  // Helper method to check if a date is Shabbat
  isShabbat(date = new Date()) {
    return date.getDay() === 6; // 6 is Saturday
  }
}

export const calendarService = new CalendarService();