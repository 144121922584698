import React, { useState, useRef, useEffect, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Mic, X, Loader2 } from 'lucide-react';
import { useVoice } from '../../services/voiceService';
const RecordButton = ({ isRecording, isProcessingRecord, onClick, isDarkMode }) => {
  return (
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      className={`
        relative p-3 rounded-xl flex items-center gap-2
        transition-all duration-300 ease-out
        ${isRecording 
          ? 'bg-red-500 text-white w-auto px-4' 
          : isProcessingRecord
            ? 'bg-yellow-500 text-white w-auto px-4'
            : isDarkMode 
              ? 'bg-white/10 hover:bg-white/20 text-white w-10'
              : 'bg-white/90 hover:bg-white/95 text-gray-900 w-10'
        }
      `}
    >
      {isProcessingRecord ? (
        <>
          <Loader2 className="w-5 h-5 animate-spin" />
          <span className="text-sm">Processing...</span>
        </>
      ) : isRecording ? (
        <>
          <motion.div
            animate={{ scale: [1, 1.2, 1] }}
            transition={{ duration: 1.5, repeat: Infinity }}
            className="w-2 h-2 rounded-full bg-white"
          />
          <span className="text-sm">Recording...</span>
        </>
      ) : (
        <Mic className="w-5 h-5" />
      )}
    </motion.button>
  );
};

const SendButton = ({ onClick, disabled, isProcessing }) => {
  return (
    <motion.button
      initial={{ scale: 0, opacity: 0 }}
      animate={{ scale: 1, opacity: 1 }}
      exit={{ scale: 0, opacity: 0 }}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={onClick}
      disabled={disabled}
      className={`
        p-3 rounded-xl
        bg-gradient-to-br from-indigo-500 to-purple-600
        text-white
        shadow-lg shadow-indigo-500/20
        disabled:opacity-50
      `}
    >
      {isProcessing ? (
        <Loader2 className="w-5 h-5 animate-spin" />
      ) : (
        <Send className="w-5 h-5" />
      )}
    </motion.button>
  );
};

const InputArea = ({
  onSendMessage,
  isProcessing,
  theme,
  isDarkMode,
  placeholder = "Type a message...",
  className = ""
}) => {
  const [input, setInput] = useState('');
  const [height, setHeight] = useState('40px');
  const [isMobile, setIsMobile] = useState(false);
  const [isProcessingRecord, setIsProcessingRecord] = useState(false);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  
  const { 
    isRecording, 
    startRecording, 
    stopRecordingAndTranscribe 
  } = useVoice();

  // Mobile detection
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(
        ('ontouchstart' in window || navigator.maxTouchPoints > 0) && 
        window.innerWidth < 768
      );
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const adjustTextareaHeight = useCallback(() => {
    if (!textareaRef.current) return;
    
    textareaRef.current.style.height = '40px';
    const newHeight = Math.min(
      Math.max(textareaRef.current.scrollHeight, 40),
      isMobile ? 80 : 100
    );
    
    textareaRef.current.style.height = `${newHeight}px`;
    setHeight(`${newHeight}px`);
  }, [isMobile]);

  useEffect(() => {
    adjustTextareaHeight();
  }, [input, adjustTextareaHeight]);

  const handleSend = async () => {
    const trimmedInput = input.trim();
    if (trimmedInput && !isProcessing) {
      await onSendMessage(trimmedInput);
      setInput('');
      setHeight('40px');
      if (textareaRef.current) {
        textareaRef.current.style.height = '40px';
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isMobile) {
      e.preventDefault();
      handleSend();
    }
  };

  const handleVoiceRecord = async () => {
    if (isRecording) {
      setIsProcessingRecord(true);
      try {
        // On mobile, blur the textarea first to prevent keyboard issues
        if (isMobile && document.activeElement === textareaRef.current) {
          textareaRef.current.blur();
        }

        const result = await stopRecordingAndTranscribe();
        console.log('Raw transcription result:', result); // Debug log
        
        // Handle null result
        if (!result) {
          console.log('No transcription result received');
          alert('No transcription result received');
          return;
        }

        // If result is just the text string (old format)
        if (typeof result === 'string') {
          if (result.trim()) {
            requestAnimationFrame(() => {
              setInput(prev => {
                const newText = prev + (prev ? ' ' : '') + result;
                console.log('Setting new input text:', newText);
                setTimeout(adjustTextareaHeight, 0);
                return newText;
              });
            });
          }
          return;
        }

        // If result is object with text/error properties (new format)
        if (result.error) {
          console.log('Transcription error:', result.error);
          alert(result.error);
          return;
        }

        if (result.text) {
          requestAnimationFrame(() => {
            setInput(prev => {
              const newText = prev + (prev ? ' ' : '') + result.text;
              console.log('Setting new input text:', newText);
              setTimeout(adjustTextareaHeight, 0);
              return newText;
            });
          });
        }
      } catch (error) {
        console.error('Voice recording error:', error);
        alert(error.message || 'Error processing recording');
      } finally {
        setIsProcessingRecord(false);
      }
    } else {
      try {
        // Request permission explicitly first on mobile
        if (isMobile) {
          try {
            const permission = await navigator.permissions.query({ name: 'microphone' });
            if (permission.state === 'denied') {
              throw new Error('Microphone permission denied');
            }
          } catch (permError) {
            console.log('Permission check error:', permError);
            // On iOS, permission query might fail, try direct recording
          }
        }
        
        await startRecording();
      } catch (error) {
        console.error('Start recording error:', error);
        alert(error.message || 'Failed to start recording');
        setIsProcessingRecord(false);
      }
    }
  };

  return (
    <div
      ref={containerRef}
      className={`
        w-full fixed bottom-0 left-0 right-0
        bg-gradient-to-t from-background to-background/60
        backdrop-blur-lg
        ${className}
      `}
    >
      <div className="max-w-4xl mx-auto px-4 py-3">
        <motion.div layout className="flex items-end gap-2">
          <motion.div layout className="flex-1 relative">
            <textarea
              ref={textareaRef}
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onKeyDown={handleKeyDown}
              placeholder={isRecording ? "Listening..." : placeholder}
              disabled={isProcessing || isRecording}
              className={`
                w-full px-4 py-2
                rounded-2xl resize-none
                transition-all duration-200
                focus:outline-none focus:ring-2
                disabled:opacity-50
                ${isDarkMode 
                  ? 'bg-white/10 focus:ring-white/20 text-white placeholder-white/50' 
                  : 'bg-white/90 focus:ring-black/10 text-gray-900 placeholder-gray-500'
                }
              `}
              style={{
                height,
                lineHeight: '20px',
                maxHeight: isMobile ? '80px' : '100px'
              }}
            />
          </motion.div>

          <div className="flex gap-2 pb-0.5">
            <RecordButton 
              isRecording={isRecording}
              isProcessingRecord={isProcessingRecord}
              onClick={handleVoiceRecord}
              isDarkMode={isDarkMode}
            />

            <AnimatePresence mode="wait">
              {(input.trim() || isProcessing) && (
                <SendButton 
                  onClick={handleSend}
                  disabled={isProcessing || !input.trim()}
                  isProcessing={isProcessing}
                />
              )}
            </AnimatePresence>
          </div>
        </motion.div>

        {isMobile && isRecording && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="text-center text-sm mt-2 text-gray-500 dark:text-gray-400"
          >
            Tap microphone again to finish recording
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default InputArea; 