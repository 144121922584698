import React from 'react';
import { motion } from 'framer-motion';
import { User, Bell, Shield, Moon, Sun } from 'lucide-react';
import AvatarSelection from '../components/settings/AvatarSelection';

const SettingsView = ({ isDarkMode, setIsDarkMode, theme }) => {
  const tabs = [
    {
      id: 'profile',
      name: 'Profile',
      icon: User,
      component: AvatarSelection
    },
    {
      id: 'notifications',
      name: 'Notifications',
      icon: Bell,
      component: () => <div>Notifications settings coming soon...</div>
    },
    {
      id: 'privacy',
      name: 'Privacy',
      icon: Shield,
      component: () => <div>Privacy settings coming soon...</div>
    }
  ];

  const [activeTab, setActiveTab] = React.useState('profile');

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-gray-900">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex flex-col gap-8">
          {/* Header */}
          <div className="flex items-center justify-between">
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white">
              Settings
            </h1>
            <button
              onClick={() => setIsDarkMode(!isDarkMode)}
              className="p-2 rounded-lg bg-white dark:bg-gray-800 shadow-lg"
            >
              {isDarkMode ? (
                <Sun className="w-5 h-5 text-amber-500" />
              ) : (
                <Moon className="w-5 h-5 text-indigo-500" />
              )}
            </button>
          </div>

          {/* Tabs */}
          <div className="border-b border-gray-200 dark:border-gray-800">
            <nav className="flex gap-4">
              {tabs.map((tab) => {
                const Icon = tab.icon;
                return (
                  <button
                    key={tab.id}
                    onClick={() => setActiveTab(tab.id)}
                    className={`
                      relative pb-4 px-2
                      text-sm font-medium
                      ${activeTab === tab.id
                        ? 'text-indigo-500 dark:text-indigo-400'
                        : 'text-gray-500 dark:text-gray-400 hover:text-gray-700 dark:hover:text-gray-300'
                      }
                    `}
                  >
                    <div className="flex items-center gap-2">
                      <Icon className="w-4 h-4" />
                      {tab.name}
                    </div>
                    {activeTab === tab.id && (
                      <motion.div
                        layoutId="activeTab"
                        className="absolute bottom-0 left-0 right-0 h-0.5 bg-indigo-500 dark:bg-indigo-400"
                      />
                    )}
                  </button>
                );
              })}
            </nav>
          </div>

          {/* Content */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            {tabs.find(tab => tab.id === activeTab)?.component()}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SettingsView;