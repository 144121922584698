import { useState, useEffect, useCallback } from 'react';

// services/data/firebaseService.js
import { initializeApp } from 'firebase/app';
import { 
  getAuth, 
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged 
} from 'firebase/auth';
import { 
  getDatabase, 
  ref, 
  set, 
  get,
  push, 
  update, 
  remove,
  query,
  orderByChild,
  limitToLast,
  onValue,
  onDisconnect,
  serverTimestamp 
} from 'firebase/database';
import { marked } from 'marked';
import DOMPurify from 'dompurify';

const firebaseConfig = {
  apiKey: "AIzaSyDi2C1D2x9yah5E-VFg023CP7LpNjcaR9Y",
  authDomain: "chat-app-b7301.firebaseapp.com",
  databaseURL: "https://chat-app-b7301-default-rtdb.firebaseio.com",
  projectId: "chat-app-b7301",
  storageBucket: "chat-app-b7301.appspot.com",
  messagingSenderId: "507382479061",
  appId: "1:507382479061:web:7cbccdf256d96b81c64a5c",
  measurementId: "G-4SLQKG0XLD"
};

// Configure marked for safe markdown parsing
marked.setOptions({
  gfm: true,
  breaks: true,
  pedantic: false,
  sanitize: false,
  smartLists: true,
  smartypants: true
});

class FirebaseService {
  constructor() {
    this.app = initializeApp(firebaseConfig);
    this.auth = getAuth(this.app);
    this.db = getDatabase(this.app);
    this.setupPresence();
    this.messageListeners = new Map();
  }
  // Add this method to your FirebaseService class
async updateUserStatus(uid, status) {
  try {
    const statusRef = ref(this.db, `status/${uid}`);
    await update(statusRef, {
      ...status,
      lastUpdated: serverTimestamp()
    });
  } catch (error) {
    console.error('Error updating user status:', error);
    throw error;
  }
}

// Then modify your login method to handle errors better
async login(email, password) {
  try {
    const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
    
    // Only update status if login was successful
    if (userCredential.user) {
      const userName = email.split('@')[0];
      try {
        await this.updateUserStatus(userCredential.user.uid, {
          online: true,
          lastSeen: serverTimestamp(),
          displayName: userName
        });
      } catch (statusError) {
        // Log the error but don't fail the login
        console.warn('Failed to update user status:', statusError);
      }
    }
    
    return userCredential.user;
  } catch (error) {
    console.error('Login error:', error);
    throw error;
  }
}

  // Message Processing Methods
  processMessageContent(message) {
    if (!message) return null;

    const processed = { ...message };

    // Process content based on message type and role
    if (message.role === 'assistant') {
      processed.type = 'markdown';
      if (message.content) {
        // Parse markdown and sanitize HTML
        const rawHtml = marked(message.content);
        processed.htmlContent = DOMPurify.sanitize(rawHtml);
      }
    } else {
      processed.type = 'text';
      if (message.content) {
        // Basic text processing for user messages
        processed.htmlContent = DOMPurify.sanitize(message.content)
          .replace(/\n/g, '<br>')
          .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');
      }
    }

    return processed;
  }

  async deleteMessage(chatType, messageId, userId = null) {
    try {
      const path = chatType === 'family' 
        ? `familyChat/${messageId}`
        : `assistantChat/${userId}/${messageId}`;
      
      const messageRef = ref(this.db, path);
      await remove(messageRef);
    } catch (error) {
      console.error('Error deleting message:', error);
      throw error;
    }
  }

  // Enhanced Assistant Chat Methods
  async createStreamingMessage(userId, initialContent = '', role = 'assistant') {
    const messageRef = ref(this.db, `assistantChat/${userId}`);
    const newMessageRef = push(messageRef);
    
    const message = {
      role,
      content: initialContent,
      type: role === 'assistant' ? 'markdown' : 'text',
      timestamp: serverTimestamp(),
      status: 'streaming'
    };

    await set(newMessageRef, message);
    return newMessageRef.key;
  }

  async updateStreamingMessage(userId, messageId, content, final = false) {
    const messageRef = ref(this.db, `assistantChat/${userId}/${messageId}`);
    const updates = {
      content,
      ...(final && { status: 'complete' })
    };

    if (final) {
      // Process final markdown content
      const processedMessage = this.processMessageContent({
        role: 'assistant',
        content
      });
      updates.htmlContent = processedMessage.htmlContent;
    }

    await update(messageRef, updates);
  }

  async sendAssistantMessage(userId, content, role = 'user', options = {}) {
    try {
      const messageRef = ref(this.db, `assistantChat/${userId}`);
      const newMessageRef = push(messageRef);
      
      const processedMessage = this.processMessageContent({
        role,
        content,
        ...options
      });

      const message = {
        ...processedMessage,
        timestamp: serverTimestamp(),
        status: 'complete'
      };

      await set(newMessageRef, message);
      return newMessageRef.key;
    } catch (error) {
      console.error('Error sending assistant message:', error);
      throw error;
    }
  }

  // Enhanced Message Subscription
  subscribeAssistantChat(userId, callback, limit = 50) {
    // Clear any existing listener for this user
    if (this.messageListeners.has(userId)) {
      this.messageListeners.get(userId)();
    }

    const chatRef = query(
      ref(this.db, `assistantChat/${userId}`),
      orderByChild('timestamp'),
      limitToLast(limit)
    );

    const unsubscribe = onValue(chatRef, (snapshot) => {
      const messages = [];
      snapshot.forEach((childSnapshot) => {
        const rawMessage = childSnapshot.val();
        const processedMessage = this.processMessageContent({
          id: childSnapshot.key,
          ...rawMessage
        });
        messages.push(processedMessage);
      });

      // Sort messages by timestamp
      const sortedMessages = messages.sort((a, b) => {
        return (a.timestamp || 0) - (b.timestamp || 0);
      });

      callback(sortedMessages);
    });

    this.messageListeners.set(userId, unsubscribe);
    return unsubscribe;
  }

    // Family Chat Methods
    subscribeFamilyChat(callback, limit = 50) {
      const chatRef = query(
        ref(this.db, 'familyChat'),
        orderByChild('timestamp'),
        limitToLast(limit)
      );
  
      const unsubscribe = onValue(chatRef, (snapshot) => {
        const messages = [];
        snapshot.forEach((childSnapshot) => {
          const message = {
            id: childSnapshot.key,
            ...childSnapshot.val(),
            // Ensure type exists
            type: childSnapshot.val().type || 'text'
          };
          messages.push(message);
        });
        callback(messages);
      });
  
      return unsubscribe;
    }
  
    async sendFamilyMessage(userId, content, type = 'text', options = {}) {
      try {
        const messageRef = ref(this.db, 'familyChat');
        const newMessageRef = push(messageRef);
        
        const message = {
          userId,
          content,
          type,
          timestamp: serverTimestamp(),
          userName: this.auth.currentUser?.displayName || this.auth.currentUser?.email?.split('@')[0],
          userPhoto: this.auth.currentUser?.photoURL,
          ...options
        };
  
        await set(newMessageRef, message);
        return newMessageRef.key;
      } catch (error) {
        console.error('Error sending family message:', error);
        throw error;
      }
    }
  
    // Method to get user's display information
    getUserDisplayInfo(userId) {
      const userRef = ref(this.db, `users/${userId}`);
      return get(userRef).then((snapshot) => snapshot.val() || {});
    }
  
    // Method to update user's display information
    async updateUserDisplayInfo(userId, updates) {
      const userRef = ref(this.db, `users/${userId}`);
      await update(userRef, {
        ...updates,
        updatedAt: serverTimestamp()
      });
    }
  
    // Method to handle family message reactions
    async addMessageReaction(messageId, userId, reaction) {
      const reactionRef = ref(this.db, `familyChat/${messageId}/reactions/${userId}`);
      await set(reactionRef, {
        type: reaction,
        timestamp: serverTimestamp()
      });
    }
  
    // Method to remove family message reactions
    async removeMessageReaction(messageId, userId) {
      const reactionRef = ref(this.db, `familyChat/${messageId}/reactions/${userId}`);
      await remove(reactionRef);
    }
  
    // Method to delete family messages
    async deleteFamilyMessage(messageId) {
      try {
        const messageRef = ref(this.db, `familyChat/${messageId}`);
        await remove(messageRef);
      } catch (error) {
        console.error('Error deleting family message:', error);
        throw error;
      }
    }

  // Enhanced Message Operations
  async updateMessage(userId, messageId, updates, type = 'assistant') {
    try {
      const path = type === 'family' 
        ? `familyChat/${messageId}`
        : `assistantChat/${userId}/${messageId}`;

      // Process content if it's being updated
      if (updates.content) {
        const processedMessage = this.processMessageContent({
          role: type === 'assistant' ? 'assistant' : 'user',
          content: updates.content
        });
        updates.htmlContent = processedMessage.htmlContent;
        updates.type = processedMessage.type;
      }

      await update(ref(this.db, path), {
        ...updates,
        updatedAt: serverTimestamp()
      });
    } catch (error) {
      console.error('Error updating message:', error);
      throw error;
    }
  }

  // Keep existing auth and presence methods
  async login(email, password) {
    try {
      const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
      const userName = email.split('@')[0];
      await this.updateUserStatus(userCredential.user.uid, {
        online: true,
        lastSeen: serverTimestamp(),
        displayName: userName
      });
      return userCredential.user;
    } catch (error) {
      console.error('Login error:', error);
      throw error;
    }
  }

   // Method to handle message reactions
   async toggleMessageReaction(messageId, userId, reaction, chatType = 'family') {
    try {
      const path = chatType === 'family' 
        ? `familyChat/${messageId}/reactions/${userId}`
        : `assistantChat/${userId}/${messageId}/reactions/${userId}`;
      
      const reactionRef = ref(this.db, path);
      const snapshot = await get(reactionRef);

      if (snapshot.exists() && snapshot.val() === reaction) {
        await remove(reactionRef);
      } else {
        await set(reactionRef, reaction);
      }
    } catch (error) {
      console.error('Error toggling reaction:', error);
      throw error;
    }
  }

  async logout() {
    try {
      const uid = this.auth.currentUser?.uid;
      if (uid) {
        await this.updateUserStatus(uid, {
          online: false,
          lastSeen: serverTimestamp()
        });
      }
      await signOut(this.auth);
    } catch (error) {
      console.error('Logout error:', error);
      throw error;
    }
  }

  setupPresence() {
    onAuthStateChanged(this.auth, (user) => {
      if (user) {
        const userStatusRef = ref(this.db, `status/${user.uid}`);
        const connectedRef = ref(this.db, '.info/connected');

        onValue(connectedRef, (snapshot) => {
          if (snapshot.val() === true) {
            onDisconnect(userStatusRef).update({
              online: false,
              lastSeen: serverTimestamp()
            });

            update(userStatusRef, {
              online: true,
              lastSeen: serverTimestamp()
            });
          }
        });
      }
    });
  }

  // Cleanup method
  cleanup() {
    // Clear all message listeners
    for (const [userId, unsubscribe] of this.messageListeners) {
      unsubscribe();
    }
    this.messageListeners.clear();

    // Update user status if logged in
    if (this.auth.currentUser) {
      const statusRef = ref(this.db, `status/${this.auth.currentUser.uid}`);
      update(statusRef, {
        online: false,
        lastSeen: serverTimestamp()
      });
    }
  }
}

export const firebaseService = new FirebaseService();

// React hook for Firebase integration
export const useFirebase = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseService.auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => {
      unsubscribe();
      firebaseService.cleanup();
    };
  }, []);

  const sendMessage = useCallback(async (content, options = {}) => {
    if (!user) {
      setError(new Error('User not authenticated'));
      return;
    }

    try {
      return await firebaseService.sendAssistantMessage(user.uid, content, 'user', options);
    } catch (error) {
      setError(error);
      throw error;
    }
  }, [user]);

  return {
    user,
    loading,
    error,
    sendMessage,
    service: firebaseService
  };
};

export default firebaseService;