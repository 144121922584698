import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Check, Loader2 } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';

const avatarOptions = [
  {
    id: 'default',
    type: 'gradient',
    name: 'Default Gradient',
    colors: ['from-violet-500', 'to-fuchsia-500']
  },
  {
    id: 'ocean',
    type: 'gradient',
    name: 'Ocean Wave',
    colors: ['from-cyan-400', 'to-blue-500']
  },
  {
    id: 'sunset',
    type: 'gradient',
    name: 'Sunset Glow',
    colors: ['from-orange-400', 'to-rose-500']
  },
  {
    id: 'forest',
    type: 'gradient',
    name: 'Forest Breeze',
    colors: ['from-emerald-400', 'to-teal-500']
  },
  {
    id: 'aurora',
    type: 'gradient',
    name: 'Northern Lights',
    colors: ['from-purple-400', 'to-green-500']
  },
  {
    id: 'golden',
    type: 'gradient',
    name: 'Golden Hour',
    colors: ['from-amber-400', 'to-orange-500']
  },
  // Animal-themed avatars
  {
    id: 'lion',
    type: 'icon',
    name: 'Lion',
    emoji: '🦁'
  },
  {
    id: 'owl',
    type: 'icon',
    name: 'Owl',
    emoji: '🦉'
  },
  {
    id: 'dolphin',
    type: 'icon',
    name: 'Dolphin',
    emoji: '🐬'
  },
  // Abstract pattern avatars
  {
    id: 'geometric1',
    type: 'pattern',
    name: 'Geometric',
    pattern: 'bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-indigo-500/20 via-purple-500/40 to-pink-500/60'
  },
  {
    id: 'dots',
    type: 'pattern',
    name: 'Dotted',
    pattern: 'bg-gradient-to-r from-gray-100 via-gray-200 to-gray-300 [background-size:20px_20px] [background-image:radial-gradient(circle,currentColor_1px,transparent_1px)]'
  },
  {
    id: 'waves',
    type: 'pattern',
    name: 'Waves',
    pattern: 'bg-[linear-gradient(45deg,transparent_25%,currentColor_25%,currentColor_50%,transparent_50%,transparent_75%,currentColor_75%)] bg-indigo-500/20 [background-size:20px_20px]'
  }
];

const AvatarSelection = () => {
  const { user, updateUserProfile } = useAuth();
  const [selectedAvatar, setSelectedAvatar] = useState(user?.avatarId || 'default');
  const [isUpdating, setIsUpdating] = useState(false);

  const handleAvatarSelect = async (avatarId) => {
    try {
      setIsUpdating(true);
      setSelectedAvatar(avatarId);
      
      // Update user profile in Firebase
      await updateUserProfile({
        avatarId: avatarId,
        // Add any other avatar-related data here
      });
    } catch (error) {
      console.error('Error updating avatar:', error);
    } finally {
      setIsUpdating(false);
    }
  };

  const renderAvatar = (avatar) => {
    switch (avatar.type) {
      case 'gradient':
        return (
          <div className={`
            w-full h-full rounded-full
            bg-gradient-to-br ${avatar.colors.join(' ')}
          `} />
        );
      
      case 'icon':
        return (
          <div className="w-full h-full rounded-full bg-gradient-to-br from-gray-100 to-gray-200 dark:from-gray-800 dark:to-gray-900 flex items-center justify-center text-2xl">
            {avatar.emoji}
          </div>
        );
      
      case 'pattern':
        return (
          <div className={`
            w-full h-full rounded-full
            ${avatar.pattern}
          `} />
        );
      
      default:
        return null;
    }
  };

  return (
    <div className="w-full max-w-4xl mx-auto p-6">
      <div className="space-y-6">
        <div>
          <h2 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Choose Your Avatar
          </h2>
          <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
            Select an avatar that represents you in chats and messages.
          </p>
        </div>

        {/* Preview of selected avatar */}
        <div className="flex items-center gap-4">
          <div className="relative w-20 h-20">
            {isUpdating && (
              <div className="absolute inset-0 flex items-center justify-center bg-black/20 rounded-full">
                <Loader2 className="w-6 h-6 text-white animate-spin" />
              </div>
            )}
            <div className="w-full h-full rounded-full overflow-hidden border-4 border-white dark:border-gray-800 shadow-xl">
              {renderAvatar(avatarOptions.find(a => a.id === selectedAvatar))}
            </div>
          </div>
          <div>
            <h3 className="font-medium text-gray-900 dark:text-white">
              {avatarOptions.find(a => a.id === selectedAvatar)?.name}
            </h3>
            <p className="text-sm text-gray-500 dark:text-gray-400">
              Your selected avatar
            </p>
          </div>
        </div>

        {/* Avatar grid */}
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-4">
          {avatarOptions.map((avatar) => (
            <motion.button
              key={avatar.id}
              onClick={() => handleAvatarSelect(avatar.id)}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`
                relative aspect-square rounded-2xl p-2
                ${selectedAvatar === avatar.id 
                  ? 'ring-2 ring-indigo-500 dark:ring-indigo-400' 
                  : 'hover:ring-2 hover:ring-gray-200 dark:hover:ring-gray-700'
                }
                bg-white dark:bg-gray-900 shadow-lg
                transition-all duration-200
              `}
            >
              <div className="w-full h-full rounded-xl overflow-hidden">
                {renderAvatar(avatar)}
              </div>
              
              {selectedAvatar === avatar.id && (
                <div className="absolute top-2 right-2 w-6 h-6 rounded-full bg-indigo-500 text-white flex items-center justify-center">
                  <Check className="w-4 h-4" />
                </div>
              )}
              
              <div className="absolute -bottom-2 left-1/2 -translate-x-1/2 px-2 py-1 bg-white dark:bg-gray-900 rounded-full text-xs font-medium shadow-lg whitespace-nowrap">
                {avatar.name}
              </div>
            </motion.button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AvatarSelection;