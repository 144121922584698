// services/ai/ai21Service.js
import EventEmitter from 'events';
import { calendarService } from '../knowledge/calendarService';

const AI21_API_KEY = 'wxuFfL9sPF1ETd5Rcv4iF7CkxbEJFkIO';
const API_URL = 'https://api.ai21.com/studio/v1/chat/completions';

class StreamingResponseEmitter extends EventEmitter {
  constructor() {
    super();
    this.content = '';
  }

  addContent(newContent) {
    this.content += newContent;
    this.emit('content', this.content);
  }

  finish() {
    this.emit('done', this.content);
  }

  error(error) {
    this.emit('error', error);
  }
}

class AI21Service {
  constructor() {
    this.controller = null;
  }

  async getJewishContext(userMessage = '') {
    const isJewishQuery = userMessage.toLowerCase().includes('parsha') || 
                         userMessage.toLowerCase().includes('torah') ||
                         userMessage.toLowerCase().includes('holiday') ||
                         userMessage.toLowerCase().includes('shabbat') ||
                         userMessage.toLowerCase().includes('shabbos') ||
                         userMessage.toLowerCase().includes('zmanim') ||
                         userMessage.toLowerCase().includes('mincha') ||
                         userMessage.toLowerCase().includes('maariv') ||
                         userMessage.toLowerCase().includes('time');

    let contextString = '';

    try {
      // Always get basic parsha info
      const parsha = await calendarService.getParsha();
      if (parsha) {
        contextString += `\nCurrent Parsha: ${parsha.name} (${parsha.hebrew}) - ${parsha.formattedDate}`;
      }

      // For Shabbat/time-related queries, get detailed times
      if (userMessage.toLowerCase().includes('shabbat') || 
          userMessage.toLowerCase().includes('shabbos') ||
          userMessage.toLowerCase().includes('time') ||
          userMessage.toLowerCase().includes('zmanim')) {
        
        const shabbatTimes = await calendarService.getShabbatTimes();
        const zmanim = await calendarService.getZmanim();
        
        contextString += '\n\n' + shabbatTimes.formatted;
        contextString += '\n\n' + calendarService.formatZmanimForPrompt(zmanim);
      }

      // For holiday/event queries, get calendar info
      if (isJewishQuery) {
        const events = await calendarService.getJewishCalendarInfo();
        if (events?.length) {
          contextString += '\n\nUpcoming Jewish Events:\n';
          contextString += calendarService.formatEventsForPrompt(events);
        }
      }

      return contextString;
    } catch (error) {
      console.error('Error getting Jewish context:', error);
      return contextString; // Return whatever we got before the error
    }
  }

  async createSystemMessage(user, options = {}) {
    const currentDate = new Date();
    const userMessage = options.userMessage || '';
    
    let systemMessage = `You are the GoFamily Assistant speaking with ${user.firstName} on ${currentDate.toLocaleDateString('en-US', { 
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    })} at ${currentDate.toLocaleTimeString('en-US')}.

    You are a family-oriented AI assistant that can:
    - Provide clear, engaging, and age-appropriate responses
    - Help with homework, learning, and creative projects
    - Share Jewish knowledge and context
    - Explain Jewish concepts and traditions
    - Keep track of prayer times and Shabbat times
    - Format responses using markdown for better readability

    Response Guidelines:
    - Use markdown formatting for structure
    - Utilize headings (# and ##) for clear sections
    - Format code blocks with triple backticks and language name
    - Use bullet points and numbered lists appropriately
    - Bold important points with **asterisks**
    - When discussing times, always note they are for Johannesburg

    Family Context:
    - Father (Brent): Works in youth employment, interested in technology and education
    - Mother (Vikki): Doctor, practical and witty
    - Grace (10): Thoughtful, creative, enjoys learning
    - Isaiah (6): Curious explorer, interested in sports and big questions
    - Rina (1.5): Learning to speak

    Communication Style:
    - Adapt tone and complexity based on who you're speaking with
    - Use clear, engaging language
    - Include gentle humor when appropriate
    - Break down complex topics into digestible parts
    - Encourage further questions and exploration
    - For prayer times and Shabbat times, always specify timezone/location`;

    // Get Jewish context based on user message
    const jewishContext = await this.getJewishContext(userMessage);
    if (jewishContext) {
      systemMessage += `\n\nJewish Context:${jewishContext}`;
    }

    if (options.includeSoccerContext) {
      systemMessage += `\n\nSoccer Updates:\n${options.soccerInfo}`;
    }

    return {
      role: 'system',
      content: systemMessage
    };
  }

  async generateChatStream(messages, user = null, options = {}) {
    // Cancel any existing request
    if (this.controller) {
      this.controller.abort();
    }
    this.controller = new AbortController();

    const emitter = new StreamingResponseEmitter();

    try {
      // Get the latest message for context analysis
      const latestMessage = messages[messages.length - 1];
      
      // Create system message with user's latest message for context
      const systemMessage = await this.createSystemMessage(user, {
        ...options,
        userMessage: latestMessage?.content || ''
      });

      console.log('Sending chat request with system context:', {
        systemMessageLength: systemMessage.content.length,
        messageCount: messages.length,
        userMessage: latestMessage?.content
      });

      const response = await fetch(API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${AI21_API_KEY}`
        },
        body: JSON.stringify({
          messages: [systemMessage, ...messages].filter(Boolean),
          model: 'jamba-1.5-large',
          max_tokens: 2048,
          temperature: 0.7,
          stream: true
        }),
        signal: this.controller.signal
      });

      if (!response.ok) {
        throw new Error(`AI21 API request failed: ${response.statusText}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      const processStream = async () => {
        try {
          while (true) {
            const { done, value } = await reader.read();

            if (done) {
              emitter.finish();
              break;
            }

            const chunk = decoder.decode(value);
            const lines = chunk.split('\n');

            for (const line of lines) {
              if (line.startsWith('data: ')) {
                const data = line.slice(6);
                
                if (data === '[DONE]') continue;

                try {
                  const parsed = JSON.parse(data);
                  const content = parsed.choices[0]?.delta?.content || '';
                  if (content) {
                    emitter.addContent(content);
                  }
                } catch (e) {
                  console.error('Error parsing streaming response:', e);
                }
              }
            }
          }
        } catch (error) {
          emitter.error(error);
        }
      };

      processStream();
      return emitter;

    } catch (error) {
      emitter.error(error);
      return emitter;
    }
  }

  cancel() {
    if (this.controller) {
      this.controller.abort();
      this.controller = null;
    }
  }
}

export const ai21Service = new AI21Service();