import { motion, AnimatePresence } from 'framer-motion';
import { Volume2, XCircle, Trash2, Loader2 } from 'lucide-react';
import DOMPurify from 'dompurify';
import { useVoice } from '../../services/voiceService';
import React, { useState, useCallback, useEffect } from 'react';

const useNativeSpeech = () => {
  const [isNativeSpeaking, setIsNativeSpeaking] = useState(false);
  const speechSynthesis = window.speechSynthesis;

  useEffect(() => {
    return () => {
      if (speechSynthesis.speaking) {
        speechSynthesis.cancel();
      }
    };
  }, []);

  const speakNative = useCallback((text) => {
    if (isNativeSpeaking) {
      speechSynthesis.cancel();
      setIsNativeSpeaking(false);
      return;
    }

    const utterance = new SpeechSynthesisUtterance(text);
    
    // Try to find and use Siri's voice on iOS devices
    let voices = speechSynthesis.getVoices();
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    
    if (isIOS && isSafari) {
      // Look for Samantha (Siri's voice) or other enhanced voices
      const siriVoice = voices.find(voice => 
        voice.name.includes('Samantha') || 
        (voice.name.includes('en-US') && voice.localService)
      );
      if (siriVoice) {
        utterance.voice = siriVoice;
      }
    }

    utterance.onstart = () => setIsNativeSpeaking(true);
    utterance.onend = () => setIsNativeSpeaking(false);
    utterance.onerror = () => setIsNativeSpeaking(false);

    speechSynthesis.speak(utterance);
  }, [isNativeSpeaking]);

  return { speakNative, isNativeSpeaking };
};

const useDoubleTap = (onDoubleTap, delay = 300) => {
  const [lastTap, setLastTap] = useState(0);

  const handleTap = useCallback(() => {
    const now = Date.now();
    if (now - lastTap < delay) {
      onDoubleTap();
    }
    setLastTap(now);
  }, [onDoubleTap, lastTap, delay]);

  return handleTap;
};

const Avatar = ({ role, name, photoURL, size = 8 }) => {
  // Convert size to pixels for inline style
  const sizeInPx = {
    width: `${size * 4}px`,
    height: `${size * 4}px`
  };

  if (role === 'assistant') {
    return (
      <div 
        style={sizeInPx}
        className="rounded-full overflow-hidden flex-shrink-0 flex items-center justify-center"
      >
        <svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="avatar-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor="#9333EA" />
              <stop offset="100%" stopColor="#6366F1" />
            </linearGradient>
          </defs>
          <circle cx="20" cy="20" r="18" fill="url(#avatar-gradient)" />
          <circle cx="16" cy="16" r="12" fill="#A855F7" fillOpacity="0.7" />
          <circle cx="24" cy="18" r="10" fill="#8B5CF6" fillOpacity="0.7" />
          <path 
            d="M14 22c3 4 9 4 12 0" 
            fill="none" 
            stroke="white" 
            strokeWidth="2" 
            strokeLinecap="round" 
          />
          <circle cx="15" cy="18" r="2" fill="white" fillOpacity="0.9" />
          <circle cx="25" cy="18" r="2" fill="white" fillOpacity="0.9" />
        </svg>
      </div>
    );
  }

  if (photoURL) {
    return (
      <div 
        className={`w-${size} h-${size} rounded-full overflow-hidden flex-shrink-0 border-2 border-white/10`}
        style={{ backgroundImage: `url(${photoURL})`, backgroundSize: 'cover' }}
      />
    );
  }

  const stringToColor = (str) => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 45%)`; 
  };

  return (
    <div 
      className={`w-${size} h-${size} rounded-full flex items-center justify-center flex-shrink-0 border-2 border-white/10`}
      style={{ backgroundColor: stringToColor(name || 'User') }}
    >
      <span className="text-white font-medium text-sm">
        {(name || 'U').charAt(0).toUpperCase()}
      </span>
    </div>
  );
};

const MessageContent = ({ message, isDarkMode, isCurrentUser }) => {
  const { content, type = 'text' } = message;
  
  const getContentClasses = () => {
    // Simplified content classes focusing on legibility
    const baseClasses = 'prose prose-sm max-w-none';
    
    // Add dark mode specific classes
    const darkModeClasses = isDarkMode ? 'prose-invert' : '';
    
    // Message type specific styling
    const typeClasses = {
      markdown: `
        [&_pre]:bg-gray-100 dark:[&_pre]:bg-gray-800
        [&_code]:bg-gray-100 dark:[&_code]:bg-gray-800
        [&_blockquote]:border-l-4 [&_blockquote]:border-gray-300 dark:[&_blockquote]:border-gray-600
        [&_a]:text-blue-600 dark:[&_a]:text-blue-400
        [&_ul]:-mt-2 [&_ol]:-mt-2
        [&_li]:my-0.5
      `,
      code: `
        font-mono text-sm
        [&_pre]:bg-gray-100 dark:[&_pre]:bg-gray-800
        [&_pre]:p-3 [&_pre]:rounded-lg
      `,
      text: ''
    };

    return `${baseClasses} ${darkModeClasses} ${typeClasses[type] || ''}`;
  };

  return (
    <div className={getContentClasses()}>
      {message.htmlContent ? (
        <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message.htmlContent) }} />
      ) : (
        <p className="whitespace-pre-wrap break-words text-sm leading-relaxed">
          {content}
        </p>
      )}
    </div>
  );
};

const ChatBubble = ({ 
  message, 
  isCurrentUser,
  showAvatar = true,
  isFirstInGroup = false,
  isLastInGroup = false,
  isDarkMode,
  onDelete,
  mode = 'family'
}) => {
  const { speak, isSpeaking, isProcessing, error } = useVoice();
  const { speakNative, isNativeSpeaking } = useNativeSpeech();
  const [localError, setLocalError] = useState(null);
  const isAssistant = mode === 'assistant' && message.role === 'assistant';

  // Handle OpenAI TTS for speaker button
  const handleSpeakMessage = async () => {
    if (isSpeaking) {
      return;
    }
    
    try {
      setLocalError(null);
      await speak(message.content, {
        voice: 'echo',
        speed: 1.0,
        volume: 1.0
      });
    } catch (error) {
      console.error('Speech error:', error);
      setLocalError(error.message);
    }
  };

  // Handle native speech for double tap
  const handleDoubleTap = useCallback(() => {
    speakNative(message.content);
  }, [message.content, speakNative]);

  const handleTap = useDoubleTap(handleDoubleTap);

  const getBackgroundColor = () => {
    if (isCurrentUser) {
      return isDarkMode 
        ? 'bg-blue-600' // Darker blue in dark mode
        : 'bg-blue-500'; // Lighter blue in light mode
    }
    return isDarkMode
      ? 'bg-gray-700' // Dark gray in dark mode
      : 'bg-white';    // White in light mode
  };

  return (
    <div className="w-full mx-auto px-2 sm:px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        className={`flex flex-col items-start ${isLastInGroup ? 'mb-6' : 'mb-1.5'}`}
      >
        {isFirstInGroup && (
          <div className="text-xs text-gray-500 dark:text-gray-400 mb-1 ml-1">
            {isCurrentUser ? 'You' : message?.userName || 'Assistant'}
          </div>
        )}

        <div className="flex items-start gap-3 w-full">
          {showAvatar && (
            <div className="flex-shrink-0 mt-1">
              <Avatar 
                role={isAssistant ? 'assistant' : 'user'}
                name={message?.userName || (isCurrentUser ? 'You' : 'User')}
                photoURL={message?.userPhoto}
                size={8}
              />
            </div>
          )}

<div className="group flex-grow flex items-start gap-2">
            <motion.div
              whileHover={{ scale: 1.002 }}
              onClick={handleDoubleTap}  
              className={`
                relative flex-grow px-4 py-3 rounded-2xl
                ${getBackgroundColor()}
                ${isCurrentUser 
                  ? 'text-white shadow-md' 
                  : `text-gray-900 dark:text-white shadow-sm ${
                      isDarkMode ? 'shadow-black/5' : 'shadow-black/10'
                    }`
                }
                hover:shadow-lg hover:-translate-y-0.5 transition-all duration-200
                max-w-[95%] sm:max-w-[90%] md:max-w-[95%] lg:max-w-[90%]
                cursor-pointer
              `}
            >
              <MessageContent 
                message={message}
                isDarkMode={isDarkMode}
                isCurrentUser={isCurrentUser}
              />

              <div className={`
                text-[10px] mt-1.5
                ${isCurrentUser 
                  ? 'text-white/80' 
                  : 'text-gray-500 dark:text-gray-400'
                }
              `}>
                {new Date(message?.timestamp).toLocaleTimeString([], {
                  hour: '2-digit',
                  minute: '2-digit'
                })}
              </div>

              <AnimatePresence>
                {(isSpeaking || isProcessing || localError) && (
                  <motion.div
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.8 }}
                    className="absolute bottom-full left-1/2 -translate-x-1/2 mb-2 whitespace-nowrap"
                  >
                    <div className={`
                      px-2 py-1 rounded-full text-xs backdrop-blur-sm
                      ${localError 
                        ? 'bg-red-500/20 text-red-500' 
                        : 'bg-black/20 dark:bg-white/20'
                      }
                    `}>
                      {localError 
                        ? localError 
                        : isProcessing 
                          ? "Processing..." 
                          : "Speaking..."
                      }
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>

            <div className={`
    flex-shrink-0 flex flex-col gap-1.5 mt-1
    opacity-0 group-hover:opacity-100
    transition-opacity duration-200
  `}>
    <motion.button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onClick={handleSpeakMessage}
      disabled={isProcessing}
      className={`
        p-2 rounded-full 
        ${isDarkMode 
          ? 'bg-gray-700 hover:bg-gray-600' 
          : 'bg-gray-100 hover:bg-gray-200'
        }
        ${isSpeaking ? 'text-blue-500' : ''}
        ${isProcessing ? 'cursor-wait' : 'cursor-pointer'}
        disabled:opacity-50
      `}
    >
      {isProcessing ? (
        <Loader2 className="w-4 h-4 animate-spin" />
      ) : isSpeaking ? (
        <XCircle size={16} />
      ) : (
        <Volume2 size={16} />
      )}
    </motion.button>
    

              {onDelete && (
                <motion.button
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.9 }}
                  onClick={onDelete}
                  className={`
                    p-2 rounded-full
                    ${isDarkMode 
                      ? 'bg-gray-700 hover:bg-gray-600' 
                      : 'bg-gray-100 hover:bg-gray-200'
                    }
                    text-red-500
                  `}
                >
                  <Trash2 size={16} />
                </motion.button>
              )}
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default ChatBubble;