// components/chat/LoadingIndicator.jsx
import React from 'react';
import { motion } from 'framer-motion';

// Themes definition
export const Themes = {
  clouds: {
    colors: {
      primary: {
        dark: '#9B4F96',
        main: '#D67BFF',
        light: '#FFB3FF',
      }
    }
  },
  ocean: {
    colors: {
      primary: {
        dark: '#0C356A',
        main: '#0174BE',
        light: '#36ADFF',
      }
    }
  },
  forest: {
    colors: {
      primary: {
        dark: '#1B4332',
        main: '#2D6A4F',
        light: '#95D5B2',
      }
    }
  }
};

const LoadingIndicator = ({ 
  theme, 
  size = "w-16 h-16",
  message = "",
  showMessage = false,
  variant = "default" // "default", "minimal", or "overlay"
}) => {
  const circleVariants = {
    first: {
      cx: [28, 32, 28, 32, 28],
      cy: [20, 24, 28, 24, 20],
      scale: [1, 1.2, 0.8, 1.2, 1],
      opacity: [0.8, 1, 0.8, 1, 0.8],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
        times: [0, 0.25, 0.5, 0.75, 1]
      }
    },
    second: {
      cx: [36, 32, 36, 32, 36],
      cy: [20, 24, 28, 24, 20],
      scale: [1, 0.8, 1.2, 0.8, 1],
      opacity: [0.8, 1, 0.8, 1, 0.8],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
        times: [0, 0.25, 0.5, 0.75, 1]
      }
    },
    third: {
      cx: [32, 28, 32, 36, 32],
      cy: [28, 24, 20, 24, 28],
      scale: [1, 1.2, 0.8, 1.2, 1],
      opacity: [0.8, 1, 0.8, 1, 0.8],
      transition: {
        duration: 4,
        repeat: Infinity,
        ease: "easeInOut",
        times: [0, 0.25, 0.5, 0.75, 1]
      }
    }
  };

  const circles = [
    { 
      initialCx: 28, 
      initialCy: 20, 
      r: 12, 
      color: theme?.colors?.primary?.light, 
      variant: 'first',
      blur: '0.5px',
      mixBlend: 'multiply'
    },
    { 
      initialCx: 36, 
      initialCy: 20, 
      r: 12, 
      color: theme?.colors?.primary?.main, 
      variant: 'second',
      blur: '0.75px',
      mixBlend: 'multiply'
    },
    { 
      initialCx: 32, 
      initialCy: 28, 
      r: 8, 
      color: theme?.colors?.primary?.dark, 
      variant: 'third',
      blur: '1px',
      mixBlend: 'screen'
    }
  ];

  return (
    <div className={`${size} relative`}>
      <motion.div
        animate={{ rotate: 360 }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "linear"
        }}
        className="w-full h-full"
      >
        <svg 
          viewBox="0 0 64 64" 
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full drop-shadow-lg"
        >
          <defs>
            <filter id="glow" x="-50%" y="-50%" width="200%" height="200%">
              <feGaussianBlur stdDeviation="2" result="coloredBlur"/>
              <feMerge>
                <feMergeNode in="coloredBlur"/>
                <feMergeNode in="SourceGraphic"/>
              </feMerge>
            </filter>
          </defs>
          
          <g style={{ transformOrigin: 'center' }} filter="url(#glow)">
            {circles.map((circle, index) => (
              <motion.circle
                key={index}
                initial={{
                  cx: circle.initialCx,
                  cy: circle.initialCy,
                  r: circle.r,
                  opacity: 0.8,
                  scale: 1
                }}
                variants={circleVariants}
                animate={circle.variant}
                fill={circle.color}
                style={{
                  mixBlendMode: circle.mixBlend,
                  filter: `blur(${circle.blur})`
                }}
              />
            ))}
          </g>
        </svg>
      </motion.div>

      {showMessage && message && (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="mt-4 text-sm text-center font-medium opacity-80"
        >
          {message}
        </motion.div>
      )}
    </div>
  );
};

export default LoadingIndicator;